import { useState } from "react";

const ChatForm = ({ responseGenerate }) => {
  const [inputText, setInputText] = useState("");

  // Função para enviar a mensagem
  const handleSubmit = () => {
    if (inputText.trim() !== "") {
      responseGenerate(inputText, setInputText);
      setInputText("");  // Limpa o campo de texto
    }
  };

  // Função para lidar com a tecla Enter
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();  // Evita quebra de linha no input
      handleSubmit();      // Chama a função de envio
    }
  };

  return (
    <div className="inputContainer">
      <div className="formDiv">
        <input
          className="formControl"
          placeholder="Como posso te ajudar?"
          value={inputText}
          onChange={(e) => setInputText(e.target.value)}
          onKeyDown={handleKeyDown}
        />
        <button
          onClick={handleSubmit}  // Usa a função handleSubmit no clique do botão
          className="formBtn"
        >
          <i className="fa fa-send"/>
        </button>
      </div>
    </div>
  );
};

export default ChatForm;
