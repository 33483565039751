import React from "react";

const Footer = () => {
  return (
  	<center>
	    <div className="footer invisibleIfSmall">
	      <i className="fa fa-shield"/> Pergunte tranquilo. Rag'n Rock protege seus dados!
	    </div>
    </center>
  );
};

export default Footer;