import loader_img1 from './thinking_dribbble.gif';
import loader_img2 from './alcohol-drink.gif'; 
import { getAccount } from "./Utils";

const DATA = getAccount() == undefined ? "NONE" : getAccount().toUpperCase();

export const name = DATA == "SL" ? "KZ-Miro" : DATA == "AMBEV" ? "FiVi" : "RAG'N ROCK";
export const database = DATA == "SL" ? "SUPERLÓGICA_POC" : DATA == "AMBEV" ? "AMBEV_POC" : "SUPERLÓGICA_POC";
export const model = DATA == "SL" ? "ragnrock-sl-paraf-0.0.0" : DATA == "AMBEV" ? "ragnrock-paraf-0.0.0" : "ragnrock-sl-paraf-0.0.0";
export const presentation = DATA == "SL" ? "Sou seu assistente digital especializado em análise de dados imobiliários. Meu papel aqui é te realizar cálculos em cima das bases de dados que você me forneceu."
							: DATA == "AMBEV" ? "Sou seu assistente digital especializado em negócios, para oferecer suporte e informações sobre temas como estratégias de mercado, análise financeira, gestão de equipes e inovação na Ambev." :
							"Olá, sou o Rag'n Rock. Levo esse nome porque meu criador estava tentando montar um RAG enquanto ouvia um Rock Progressivo e acabou encontrando algo melhor, no caso, eu. :-) Me pergunte algo que estarei feliz em poder te ajudar.";
export const loader = DATA == "SL" ? loader_img1 : DATA == "AMBEV" ? loader_img2 : loader_img1;

export const bodyBG = DATA == "SL" ? {background: "rgb(16, 52, 242)"} : DATA == "AMBEV" ? {background: "rgb(74 131 195)"} : {background: "rgb(40, 55, 68)"};
export const bodyCNT = DATA == "SL" ? {background: "rgb(47 39 211)"} : DATA == "AMBEV" ? {background: "#1D1E20"} : {background: "#2f7db2"};

export const credentials = {
    "ambev": {
        password: "FiVi@Ambev",
        domain: "ambev"
    },
    "thiago.barreto@ambev.com.br": {
        password: "FiVi@Ambev",
        domain: "ambev"
    },
    "99821045@ambev.com.br": {
        password: "FiVi@Ambev",
        domain: "ambev"
    },
    "99808475@ambev.com.br": {
        password: "FiVi@Ambev",
        domain: "ambev"
    },
    "superlogica": {
        password: "SL@KZ",
        domain: "sl"
    },
    "superlogica@superlogica.com.br": {
        password: "SL@KZ",
        domain: "sl"
    },
    "admin": {
        password: "1234",
        domain: null
    }
}