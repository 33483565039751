import React, { useEffect, useState } from 'react';
import { credentials } from "./Data.js";

function Login() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    useEffect(() => {
        import('./Login.css');
    }, []);

    const login = (event) => {
        event.preventDefault();
        console.log('Fazendo login com:', username, password);
        if (credentials[username] == null) {
            alert('Usuário não cadastrado');
        } else if (credentials[username]?.password === password) {
            sessionStorage.setItem('logged', 'true');
            sessionStorage.setItem('domain', credentials[username].domain);
            window.location.reload();
        } else {
            alert('Credenciais inválidas');
        }
    };

    return (
        <div className="login-container">
            <div className="login-box">
                <img src={require('./logo.png')} alt="Logo" className="logo" />
                <h2>Bem-vindo de volta!</h2>
                <p>Por favor, entre em sua conta para continuar</p>

                <form className="login-form" onSubmit={login}>
                    <input
                        type="text"
                        placeholder="Email ou Nome de Usuário"
                        className="input-field"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                    <input
                        type="password"
                        placeholder="Senha"
                        className="input-field"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <button type="submit" className="login-button">
                        Entrar
                    </button>
                </form>

                <div className="login-footer">
                    <p>Não tem uma conta? <a href="#">Cadastre-se</a></p>
                    <p>Esqueceu sua senha? <a href="#">Recupere-a aqui</a></p>
                </div>
            </div>
        </div>
    );
}

export default Login;
