import axios from 'axios';
import { database, model } from "./Data.js";

const basePath = window.location.host.includes("localhost") ? "http://localhost:8242/api/" : "https://www.fluxer.me/api/ragnrock/";

export async function loadContexts() {
  const url = basePath + 'scheme/' + model;
  try {
    var response = await axios.get(url + '/DIM_T');
    sessionStorage.setItem('DIM_T', response.data);
    response = await axios.get(url + '/context');
    sessionStorage.setItem('context', response.data);
  } catch (error) {
    console.error('Erro ao fazer a chamada à API:', error.response ? error.response.data : error.message);
    alert('O GPT morreu. =/ Favor tente novamente.');
  }
}

export async function getMemory() {
  const url = basePath + 'scheme/' + model;
  try {
    var response = await axios.get(url + '/memory');
    return response.data;
  } catch (error) {
    console.error('Erro ao fazer a chamada à API:', error.response ? error.response.data : error.message);
    alert('O GPT morreu. =/ Favor tente novamente.');
  }
}

export async function registerMemory(memo) {
    const url = basePath + 'scheme/property';
    const headers = { 'Content-Type': 'application/json' };
    const data = {
      "name": model,
      "key": "memory",
      "value": memo
    };
    try {
      await axios.post(url, data, { headers });
    } catch (error) {
      console.error('Erro ao fazer a chamada à API:', error.response ? error.response.data : error.message);
      alert('O GPT morreu. =/ Favor tente novamente.');
    }
    return null;	
}

export async function callAgent(endpoint, scheme, input) {
    const url = basePath + endpoint;
    const headers = { 'Content-Type': 'application/json' };
    const data = {
      "scheme": scheme,
      "values": [input],
      "args": {
        "today": new Date().toISOString(),
        "memory": JSON.parse(sessionStorage.getItem('memory'))
      }
    };
    try {
      const response = await axios.post(url, data, { headers });
      return response.data;
    } catch (error) {
      console.error('Erro ao fazer a chamada à API:', error.response ? error.response.data : error.message);
      alert('O GPT morreu. =/ Favor tente novamente.');
    }
    return null;
}

export async function callTool(endpoint, data) {
    const url = basePath + endpoint;
    const headers = { 'Content-Type': 'application/json' };
    try {
      const response = await axios.post(url, data, { headers });
      return response.data;
    } catch (error) {
      console.error('Erro ao fazer a chamada à API:', error.response ? error.response.data : error.message);
      alert('O GPT morreu. =/ Favor tente novamente.');
    }
    return null;
}