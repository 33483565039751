import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMemory, faTrash, faPlus, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import './Header.css';
import { registerMemory, getMemory } from "../Api.js";
import { name } from "../Data.js";

const Header = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [items, setItems] = useState([]);

  useEffect(() => {
    getMemory().then(memory=>{
      setItems(memory);
    })
  }, []); 

  useEffect(() => {
    sessionStorage.setItem('memory', JSON.stringify(items));
  }, [items]);

  const logout = () => {
    sessionStorage.removeItem('domain');
    sessionStorage.removeItem('logged');
    window.location.reload();
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const addItem = () => {
    setItems([...items, ""]);
  };

  const removeItem = (index) => {
    const newItems = items.filter((_, i) => i !== index);
    setItems(newItems);
  };

  const updateItem = (index, value) => {
    const newItems = [...items];
    newItems[index] = value;
    setItems(newItems);
  };

  const saveItems = () => {
    registerMemory(items).then(t=>{
      toggleModal();
    });
  };
  return (
    <div className="header">
      <FontAwesomeIcon
        icon={faSignOutAlt}
        className="logout-icon"
        onClick={logout}
      />
    
      {/* Ícone de Memória */}
      <FontAwesomeIcon
        icon={faMemory}
        className="memory-icon"
        onClick={toggleModal}
      />

      <div className="headerSubDiv">
        <h1 className="headerText">{name}</h1>
      </div>

      {/* Modal */}
      {isModalOpen && (
        <div className="modal-overlay" onClick={toggleModal}>
          <div
            className="modal-content"
            onClick={(e) => e.stopPropagation()}
            style={{ textAlign: "left" }}
          >
            <h2 style={{color: "black"}}>Memória</h2>
            <div className="crud-container">
              {items?.map((item, index) => (
                <div key={index} className="input-group">
                  <input
                    type="text"
                    value={item}
                    onChange={(e) => updateItem(index, e.target.value)}
                    className="input-item"
                  />
                  <FontAwesomeIcon
                    icon={faTrash}
                    className="trash-icon"
                    onClick={() => removeItem(index)}
                  />
                </div>
              ))}
              <button className="add-button" onClick={addItem}>
                <FontAwesomeIcon icon={faPlus} /> Adicionar
              </button>
            </div>
            <button className="save-button" onClick={saveItems}>
              Salvar
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Header;
