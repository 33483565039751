import React, { useState, useEffect } from 'react';
import ChatForm from "./Components/ChatForm";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import AnswerSec from "./Components/Answer";
import ResultPanel from "./Components/ResultPanel";
import Login from "./Login";
import { presentation, loader, bodyBG } from "./Data.js";
import { loadContexts, callAgent, callTool } from "./Api.js";
import { flow } from "./Flow.js";
import "./App.css";

const App = () => {
  var [cache, setCache] = useState({});
  const [botMsg, setBotMsg] = useState(null);
  const [state, setState] = useState(null);
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [updateState, setUpdateState] = useState(false);
  
  const update = () => {
    setUpdateState(!updateState);
  }
  
  const getCurrentState = () => {
    if (state != null) {
      return flow[state];
    }
    return null;
  };

  useEffect(() => {
    loadContexts();
    if (state == null) {
      for (let i in flow) {
        if (flow[i].start) {
          setState(i);
        }
      }
    }
    document.body.style.background = bodyBG.background;
  }, []);

  useEffect(() => {
    if (state !== null) {
      run();
    }
  }, [state]);

  useEffect(() => {
    if (state !== null) {
      move(getCurrentState());
    }
  }, [cache]);


  function mergeCache(newCache, value) {
    if (!value) 
      return newCache;
    return {...newCache, ...value};
  }

  function appendAllCache(newCache, value) {
    if (!value)
      return newCache;
    for (let j in value) {
      if (newCache[j]) {
        if (Array.isArray(newCache[j])) {
          newCache[j].push(value[j]);
        } else {
          newCache[j] = [newCache[j], value[j]];
        }
      } else {
        newCache[j] = value[j];
      }
    }
    return newCache;
  }

  function appendCache(newCache, operation, value, attr) {
    const args = extractArgs(operation);
    if (attr) {
      for (let j of args) {
        if (newCache[j]) {
          if (Array.isArray(newCache[j])) {
            newCache[j].push(attr);
          } else {
            newCache[j] = [newCache[j], attr];
          }
        } else {
          newCache[j] = attr;
        }
      }
      return newCache;  
    }
    if (!value)
      return newCache;
    for (let j of args) {
      if (newCache[j] && value[j]) {
        if (Array.isArray(newCache[j])) {
          newCache[j].push(value[j]);
        } else {
          newCache[j] = [newCache[j], value[j]];
        }
      } else if (value[j]) {
        newCache[j] = value[j];
      }
    }
    return newCache;
  }

  function remainsCache(newCache, args) {
    let finalCache = [];
    let remainedCache = {};
    for (let a of args) {
      if (cache[a]) {
        if (remainedCache[a + "[ANTERIORES]"] == null)
          remainedCache[a + "[ANTERIORES]"] = [];
        remainedCache[a + "[ANTERIORES]"].push(cache[a]);
      }
    }
    finalCache.push(remainedCache);
    return finalCache;
  }
  
  function attrib(value, currentState) {
    if (currentState.attrib == null)
      return value;
    let allVars = extractVars(currentState.attrib);
    let exp = currentState.attrib;
    for (let v of allVars) {
      if (value[v] != null && value[v] != "null")
        exp = exp.replace("${" + v + "}", JSON.stringify(value[v]));
      exp = exp.replace("${" + v + "}", cache[v] == "null" ? "null" : JSON.stringify(cache[v]));
    }
    try {
      const res = eval(exp);
      return res;
    } catch (error) {
      console.error(error, exp);
    }
    return value;
  }

  function caching(value, currentState) {
    const operation = currentState.cache;
    const attr = attrib(value, currentState);
    setCache((prevCache) => {
      let newCache = {...prevCache};
      if (currentState.remains) {
        newCache = remainsCache(newCache, currentState.remains);
      }
      if (operation === "#reset()") {
        setMessage(null);
        newCache = {};
      } else if (operation === "#overwrite()") {
        newCache = mergeCache(newCache, value);
      } else if (operation === "#renew()") {
        newCache = mergeCache({}, value);
      } else if (operation === "#appendAll()") {
        newCache = appendAllCache(newCache, value);
      } else if (operation?.startsWith("#append")) {
        newCache = appendCache(newCache, operation, value, attr);
      } else if (operation) {
        alert("Operação de Cache não encontrada: " + operation);
      }
      return newCache;
    });
  }

  function extractArgs(input) {
    const pattern = /\$\{([^}]+)\}/g;  
    let args = [];
    const matches = input?.matchAll(pattern);
    for (const match of matches) {
      args.push(match[1]);
    }
    return args;
  }

  function getBody(req) {
    if (typeof req === "boolean" || typeof req === "number") {
      return req;
    }
    if (typeof req === "string") {
      let args = extractArgs(req);
      if (!args.length) return req;
      for (let v of args) {
        req = req.replaceAll('${' + v + '}', JSON.stringify(cache[v]));
      }
      try {
        return JSON.parse(req);
      } catch (error) {
        console.error(error, req);
        return req;
      }
    }
    let bout = {};
    for (let i in req) {
      if (Array.isArray(req[i])) {
        let list = req[i].map(getBody);
        bout[i] = list;
      } else {
        bout[i] = getBody(req[i]);
      }
    }
    return bout;
  }

  async function run(input) {
    let currentState = getCurrentState();
    switch (currentState?.type) {
      case "#user":
        setLoading(false);
        if (!input) 
          break;
        setLoading(true);
        let value = {};
        let args = extractArgs(currentState.ask);
        value[args[0]] = input;
        caching(value, currentState);
        break;
      case "#agent":
        let dataSend = {};
        if (currentState?.send) {
          for (let x of currentState.send)
            dataSend[x] = cache[x]; 
        } else {
          dataSend = cache;
        }
        let agentValue = await callAgent(currentState["function"], currentState["scheme"], dataSend);
        if (!agentValue || agentValue.error == "Não encontrou nenhum JSON") {
          let say = `<i title="Erro do ChatGPT" class="fa fa-bug" style="background: white; color: #e20a0a; padding: 5px; border-radius: 20px; margin-right: 10px; height: 25px; width: 25px; text-align: center;"></i>${agentValue.message}`;
          setBotMsg(say);
          caching(null, currentState);
          setLoading(false);
          break;
        } else {
          caching(agentValue, currentState);
        }
        break;
      case "#tool":
        let toolValue = await callTool(currentState["url"], getBody(currentState["body"]));
        caching(toolValue, currentState);
        break;
      case "#bot":
        let say = currentState.say;
        let sayArgs = extractArgs(say);
        for (let v of sayArgs) {
          var str = Array.isArray(cache[v]) ? 
            "<ul>" + cache[v].map(i=>"<li>" + i + "</li>").join(" ") + "</ul>" : cache[v];
          say = say.replaceAll('${' + v + '}', str);
        }
        say = `<i title="${currentState.name}" class="fa fa-android" style="background: white; color: black; padding: 5px; border-radius: 20px; margin-right: 10px; height: 25px; width: 25px; text-align: center;"></i>${say}`;
        setBotMsg(say);
        caching(null, currentState);
        break;
    }

    if (currentState?.publish) {
      setMessage(cache);
    }
  }

  function move(curState) {
    if (curState.goto) {
      setState(curState.goto);
    } else if (curState.cases) {
      setState(switchMove(curState.cases));
    }
  }

  const switchMove = (cases) => {
    for (let i in cases) {
      let allVars = extractVars(i);
      if (i === "$else")
        return cases[i];
      let exp = i;
      for (let v of allVars)
        exp = exp.replace("${" + v + "}", cache[v] == "null" ? "null" : JSON.stringify(cache[v]));
      try {
        const res = eval(exp);
        if (res)
          return cases[i];
      } catch (error) {
        console.error(error);
      }
    }
    return state;
  }

  const extractVars = (str) => {
    const regex = /\$\{([^}]+)\}/g;
    const matches = [];
    let match;
    while ((match = regex.exec(str)) !== null) {
      matches.push(match[1]);
    }
    return [...new Set(matches)];
  };

  const responseGenerate = async (inputText) => {
    setBotMsg(null);
    if (!inputText) 
      return;
    run(inputText);
  };

  if (sessionStorage.getItem('logged') == null)
    return <Login update={update}/>
  
  return (
    <div>
      <div className="App">
        <Header />
        <div style={{ paddingBottom: 5, height: "calc(100vh - 190px)", overflow: "auto" }}>
          <div className="header">
            {botMsg && (
              <p className={"headerSubTextBot" + (loading ? " invisibleIfSmall" : "")} dangerouslySetInnerHTML={{ __html: botMsg }}></p>
            )}
          </div>
          {message && <ResultPanel message={message} />}
          {!message && !botMsg && (
            <div style={{display: "grid", alignItems: "center", height: "100%"}}>
              <div className="header">
                <p className="headerSubText">{presentation}</p>
              </div>
            </div>
          )}
        </div>
        <ChatForm responseGenerate={responseGenerate}/>
        <Footer />
        {loading && <div className="loading">
          <center style={{ height: "unset" }}>
            <div style={{ width: 200, height: 200, textAlign: "center", padding: 60 }}>
              <img style={{ width: 100, borderRadius: 500, height: 100 }} src={loader} />
            </div>
          </center>
          <h1>Aguarde enquanto os cálculos estão sendo realizados</h1>
        </div>}

      </div>
    </div>
  );
};

export default App;
